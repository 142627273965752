

.desarrolloalamedida {
  
  width: "100%";
  background-color: white;

}

.letras-fondodeanimacion {
 color: Blue;
  text-align: center;
  
}

.elemento-centralizado {
  text-align: center;
  margin: 1rem,
  
}


.elemento-centralizado-justificado {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.botoncolorfondo {
  background-color: red;
}
